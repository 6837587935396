function getLocaleFromURL() {
  const url = window.location.hostname;
  if (url.endsWith('.br')) {
    return 'pt';
  } else if (url.endsWith('.py')) {
    return 'es';
  } else {
    return 'pt';
  }
}

const dadospt = require('../locales/pt.json');

const dadoses = require('../locales/es.json');

let dados = ''

const laguage = getLocaleFromURL()
if(laguage == 'pt'){
  dados = dadospt;
}else if(laguage == 'es'){
  dados = dadoses;
}

const terms = [
  {
    initial_content:
    dados.termos_de_uso.termos_inicio,
    article: [
      {
        title: null,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termos_conteudoInicio.conteudo1,
          dados.termos_de_uso.termos_conteudoInicio.conteudo2,
        ],
      },
      {
        title: dados.termos_de_uso.termos_title1,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termos_conteudo1,
        ],
      },
      {
        title: dados.termos_de_uso.termos_title2,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo2.conteudo1,
          dados.termos_de_uso.termo_conteudo2.conteudo2,
          dados.termos_de_uso.termo_conteudo2.conteudo3,
          dados.termos_de_uso.termo_conteudo2.conteudo4,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title3,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo3.conteudo1,
          dados.termos_de_uso.termo_conteudo3.conteudo2,
          dados.termos_de_uso.termo_conteudo3.conteudo3,
          dados.termos_de_uso.termo_conteudo3.conteudo4,
          dados.termos_de_uso.termo_conteudo3.conteudo5,
          dados.termos_de_uso.termo_conteudo3.conteudo6,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title4,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo4.conteudo1,
          dados.termos_de_uso.termo_conteudo4.conteudo2,
          dados.termos_de_uso.termo_conteudo4.conteudo3,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title5,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo5,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title6,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo6.conteudo1,
          dados.termos_de_uso.termo_conteudo6.conteudo2,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title7,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo7,
        ],
      },
      {
        title: dados.termos_de_uso.termo_title8,
        content_start: null,
        content_end: null,
        items: [
          dados.termos_de_uso.termo_conteudo8,
        ],
      },
    ],
    last_changes: null,
  },
];

module.exports = [terms];
