function getLocaleFromURL() {
  const url = window.location.hostname;
  if (url.endsWith('.br')) {
    return 'pt';
  } else if (url.endsWith('.py')) {
    return 'es';
  } else {
    return 'pt';
  }
}

const dadospt = require('../locales/pt.json');

const dadoses = require('../locales/es.json');

let dados = ''

const laguage = getLocaleFromURL()
if(laguage == 'pt'){
  dados = dadospt;
}else if(laguage == 'es'){
  dados = dadoses;
}

const faq = [
  {
    id: 1,
    q_br: dados.faq.faq1.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq1.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: null,
    image_es: null,
    image_en: null,

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 2,
    q_br: dados.faq.faq2.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq2.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: dados.hometeacher.hometeacher_termo.termo2,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: "mdi-arrow-right",

    btn_url_br: "https://makereducacional.com.br/termos/Termo%20de%20Uso.pdf",
    btn_url_es: null,
    btn_url_en: null,

    image_br:
      "https://makereducacional.com.br/astronaut/faq/PrimeiroAcesso.svg",
    image_es: null,
    image_en: null,

    img_text_br: dados.faq.faq_primeiro.primeiro,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 3,
    q_br: dados.faq.faq3.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq3.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br:
      "https://makereducacional.com.br/astronaut/faq/AstronaoutWithoutHellmet.svg",
    image_es:
      "https://makereducacional.com.br/astronaut/faq/AstronaoutWithoutHellmet.svg",
    image_en:
      "https://makereducacional.com.br/astronaut/faq/AstronaoutWithoutHellmet.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 4,
    q_br: dados.faq.faq4.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq4.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br:
      "https://makereducacional.com.br/astronaut/faq/AstronautAllien.svg",
    image_es:
      "https://makereducacional.com.br/astronaut/faq/AstronautAllien.svg",
    image_en:
      "https://makereducacional.com.br/astronaut/faq/AstronautAllien.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 5,
    q_br: dados.faq.faq5.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq5.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/MenuLateral.svg",
    image_es: null,
    image_en: null,

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 6,
    q_br: dados.faq.faq6.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq6.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/AstronautStar.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/AstronautStar.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/AstronautStar.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 7,
    q_br: dados.faq.faq7.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq7.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/Knowledge.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/Knowledge.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/Knowledge.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 8,
    q_br: dados.faq.faq8.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq8.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/Material.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/Material.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/Material.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 9,
    q_br: dados.faq.faq9.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq9.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/Teacher.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/Teacher.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/Teacher.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 10,
    q_br: dados.faq.faq10.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq10.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: dados.botoes.botao_vaparalogin,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: "mdi-login",

    btn_url_br: "/login",
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/Login.svg",
    image_es: null,
    image_en: null,

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 11,
    q_br: dados.faq.faq11.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq11.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/NoCust.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/NoCust.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/NoCust.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 12,
    q_br: dados.faq.faq12.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq12.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: dados.botoes.botao_suportetecnico,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: "mdi-whatsapp",

    btn_url_br: "https://wa.me/551935736050",
    btn_url_es: null,
    btn_url_en: null,

    image_br:
      "https://makereducacional.com.br/astronaut/faq/TechnicalSupport2.svg",
    image_es:
      "https://makereducacional.com.br/astronaut/faq/TechnicalSupport2.svg",
    image_en:
      "https://makereducacional.com.br/astronaut/faq/TechnicalSupport2.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
  {
    id: 13,
    q_br: dados.faq.faq13.id1,
    q_es: "ES",
    q_en: "EN",

    a_br: dados.faq.faq13.id2,
    a_es: "ES",
    a_en: "EN",

    btn_text_br: null,
    btn_text_es: null,
    btn_text_en: null,
    btn_icon: null,

    btn_url_br: null,
    btn_url_es: null,
    btn_url_en: null,

    image_br: "https://makereducacional.com.br/astronaut/faq/Cookies.svg",
    image_es: "https://makereducacional.com.br/astronaut/faq/Cookies.svg",
    image_en: "https://makereducacional.com.br/astronaut/faq/Cookies.svg",

    img_text_br: null,
    img_text_es: null,
    img_text_en: null,
  },
];

module.exports = [faq];
