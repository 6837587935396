<template>
  <!-- LOGIN -->
  <v-app style="background-color: #0e4887">
    <v-row no-gutters>
      <v-col cols="11" md="4" class="mx-auto my-auto">
        <v-card class="px-10 py-6 text-center rounded-lg">
          <v-img
            src="../assets/King.svg"
            width="60px"
            class="mx-auto mb-2"
          ></v-img>

          <!-- <div class="text-h5 font-weight-medium">Faça seu Login</div> -->
          <div class="mb-4 text-center">
            {{ $t('login.login_inicio.inicio1') }}
            <span v-if="$vuetify.breakpoint.smAndUp">
              {{ $t('login.login_inicio.inicio2') }}
            </span>
          </div>
          <v-form ref="adminForm">
            <v-text-field
              v-model="login.username"
              color="blue"
              :label="$t('login.login_painel.login1')"
              :rules="[rules.required]"
              dense
              filled
              :placeholder="$t('login.login_painel.login2')"
              @keyup.enter="loginAdmin()"
              @click="incorrectUP = false"
            />
            <v-text-field
              v-model="login.password"
              color="blue"
              :label="$t('login.login_painel.senha1')"
              dense
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              filled
              :placeholder="$t('login.login_painel.senha2')"
              @keyup.enter="loginAdmin()"
              @click="incorrectUP = false"
            />
            <v-alert
              v-if="incorrectUP"
              dense
              outlined
              text
              type="error"
              class="mt-n4"
            >
              {{ $t('login.login_painel.incorreta') }}
            </v-alert>
            <v-btn
              color="rgba(25, 124, 255, 0.25)"
              class="blue--text my-2"
              style="font-size: 18px"
              large
              block
              @click="loginAdmin()"
            >
              {{ $t('login.login_painel.botao') }}
            </v-btn>
          </v-form>
          <a href="../login" class="caption black--text">
            {{ $t('login.login_soualuno') }}
          </a>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      login: {},
      usertype: "admin",
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      },
      showPassword: false,
      incorrectUP: false,
    };
  },
  methods: {
    async loginAdmin() {
      try {
        let access = await axios.post(`${url}/login/admin`, this.login);
        if (access.data.status == "ERROR") return (this.incorrectUP = true);
        await localStorage.setItem("accessToken", access.data.accessToken);
        await this.toAdmin();
      } catch (error) {
        console.error("ERROR", error);
      }
    },

    //localStorage.setItem('token', login)
    toAdmin() {
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style lang="css" scoped></style>
<!-- tokenPayload() {
  let config = {
    headers: {
      'Authorization': 'Bearer ' + validToken()
    }
  }
  Axios.post( 
      'http://localhost:8000/api/v1/get_token_payloads',
      config
    )
    .then( (  ) => {
      
    } )
    .catch()
} -->
