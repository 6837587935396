<template>
  <v-app style="background-color: #dcebff">
    <v-row align="center" justify="center">
      <v-card
        :width="$vuetify.breakpoint.smAndUp ? '35%' : '85%'"
        :height="$vuetify.breakpoint.xsOnly ? '60vh' : '85vh'"
        class="pa-4 rounded-xl"
      >
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn icon color="black" @click="backStep()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <!-- <v-img
              src="../assets/MakerEducation/BlueMaker.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 80 : 120"
              class="has-show mx-auto"
            ></v-img> -->
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
        <v-row no-gutters class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="2" sm="1" class="mx-1" v-for="(n, i) in steps" :key="i">
            <v-progress-linear
              background-opacity="0.4"
              color="darkblue"
              rounded
              :value="n"
            >
            </v-progress-linear>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-window v-model="step" class="px-2">
          <v-window-item :value="1">
            <v-img
              src="../assets/Forget-3.svg"
              height="180px"
              contain
              class="my-10"
            ></v-img>
            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('student.stundent_esqueceusenha.esqueceu1') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('student.stundent_esqueceusenha.esqueceu2') }}
              </div>
            </div>
            <v-form ref="userForm">
              <v-text-field
                v-model="userRequest"
                color="darkblue"
                :label="$t('login.login_painel.login1')"
                :rules="[rules.required, rules.user]"
                class="mt-6"
              ></v-text-field>
              <v-alert
                v-if="incorrectUser"
                dense
                outlined
                text
                type="error"
                class="mt-n4"
              >
                {{ $t('regisResponsavel.usuarioexiste') }}
              </v-alert>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="rgba(25, 124, 255, 0.25)"
                  class="blue--text my-2"
                  rounded
                  @click="getNumber(userRequest)"
                  large
                  style="font-size: 18px"
                >
                  {{ $t('botoes.botao_continuar') }}
                </v-btn>
              </div>
            </v-form>
          </v-window-item>

          <v-window-item :value="2">
            <v-img
              src="../assets/Forget-4.svg"
              height="180px"
              contain
              class="mb-10 mt-6"
            >
            </v-img>

            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('student.student_confirmatelefone.confirma1') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('student.student_confirmatelefone.confirma2') }}
              </div>
            </div>
            <v-text-field
              color="darkblue"
              label="Telefone"
              v-model="phoneNumber[1]"
              readonly
              class="mt-8"
            ></v-text-field>

            <div class="d-flex justify-space-between">
              <a
                @click="noAccessDialog = true"
                class="caption black--text mt-n2 mb-12"
              >
                {{ $t('student.student_confirmatelefone.confirma3') }}
              </a>
              <v-btn
                color="rgba(25, 124, 255, 0.25)"
                class="blue--text my-2"
                rounded
                @click="sendCode(phoneNumber[0])"
                :loading="sendCodeLoading"
                large
                style="font-size: 18px"
              >
                {{ $t('botoes.botao_continuar') }}
              </v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="3">
            <v-img
              src="../assets/Forget-1.svg"
              height="180px"
              contain
              class="mb-10 mt-6"
            ></v-img>

            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('student.student_confirmatelefone.confirma4') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('student.student_confirmatelefone.confirma5') }}
              </div>
            </div>

            <v-form ref="verifyCodee">
              <v-text-field
                color="darkblue"
                label="Código "
                class="mt-8"
                v-model="OTPCode"
                :rules="[rules.required]"
              ></v-text-field>
              <v-alert
                v-if="incorrectCode"
                dense
                outlined
                text
                type="error"
                class="mt-n4"
              >
                {{ $t('student.student_confirmatelefone.confirma6') }}
              </v-alert>
              <div class="d-flex justify-end">
                <!-- <a @click="resendCode()" class="caption black--text mt-n2 mb-12">
                Reenviar código
              </a> -->
                <v-btn
                  color="rgba(25, 124, 255, 0.25)"
                  class="blue--text my-2"
                  @click="verifyCode(phoneNumber[0], OTPCode)"
                  :loading="verifyCodeLoding"
                  rounded
                  large
                  style="font-size: 18px"
                >
                  {{ $t('botoes.botao_continuar') }}
                </v-btn>
              </div>
            </v-form>
          </v-window-item>

          <v-window-item :value="4">
            <v-img
              src="../assets/Forget-2.svg"
              height="180px"
              contain
              class="my-10"
            ></v-img>
            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('student.student_confirmatelefone.confirma7') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('student.student_confirmatelefone.confirma8') }}
              </div>
            </div>
            <v-form ref="changingForm">
              <v-text-field
                :label="$t('login.login_painel.senha1')"
                :type="showPassword1 ? 'text' : 'password'"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1"
                color="darkblue"
                :rules="[rules.required, rules.length(4)]"
                v-model="password"
              ></v-text-field>
              <v-text-field
                :label="$t('label.label_senha.senha_confirmar')"
                :type="showPassword2 ? 'text' : 'password'"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2"
                color="darkblue"
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                v-model="confirmPassword"
              ></v-text-field>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="rgba(25, 124, 255, 0.25)"
                  class="blue--text my-2"
                  @click="changePassword()"
                  rounded
                  large
                  style="font-size: 18px"
                >
                  {{ $t('botoes.botao_finalizar') }}
                </v-btn>
              </div>
            </v-form>
          </v-window-item>
        </v-window>
      </v-card>
    </v-row>
    <v-snackbar
      v-model="successDialog"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('student.student_confirmatelefone.confirma9') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="successDialog = false"
          color="success"
          class="font-weight-black"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="noNumber"
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="text-justify rounded-lg">
        <v-card-title
          class="
            d-flex
            justify-center
            align-center
            font-weight-bold
            white--text
            darkblue
          "
          style="font-size: 1.5rem"
        >
          {{ $t('student.student_confirmatelefone.confirma10') }}
        </v-card-title>
        <v-card-text
          class="d-flex justify-center align-center black--text pa-6"
        >
          <div
            style="font-size: 1.1rem"
            :style="$vuetify.breakpoint.mdAndUp ? ' width: 75%' : ''"
          >
            {{ $t('alerta.alerta_telefone.telefone1') }}
            <a href="/login" class="font-weight-bold darkpink--text"> {{ $t('login.login_painel.botao') }} </a>
            {{ $t('alerta.alerta_telefone.telefone2') }}
            <a
              href="https://wa.me/551935736050"
              target="_blank"
              class="font-weight-bold"
              style="color: #25d366"
              >WhatsApp</a
            >.
          </div>
          <div style="width: 25%" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img src="../assets/SMS.svg" />
          </div>
        </v-card-text>
        <v-card-actions
          class="
            d-flex
            align-center
            justify-space-around
            mx-md-10 mx-lg-16
            pb-6
          "
        >
          <v-btn href="/login" class="rounded-lg px-5" color="darkpink" dark>
            <v-icon left>mdi-login</v-icon> {{ $t('login.login_painel.botao') }}
          </v-btn>
          <v-btn
            href="https://wa.me/551935736050"
            target="_blank"
            class="rounded-lg px-5"
            color="#25D366"
            dark
          >
            <v-icon left>mdi-whatsapp</v-icon> WhatsApp
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noAccessDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="text-justify rounded-lg">
        <v-card-title
          class="
            d-flex
            justify-center
            align-center
            font-weight-bold
            white--text
            darkblue
          "
          style="font-size: 1.5rem"
        >
          {{ $t('student_confirmatelefone.confirma11') }}
        </v-card-title>
        <v-card-text
          class="d-flex justify-center align-center black--text pa-6"
        >
          <div style="font-size: 1.1rem">
            {{ $t('student.student_problemadasenha.problema1') }}<b>{{ $t('student.student_problemadasenha.problema2') }}</b> {{ $t('student.student_problemadasenha.problema3') }}
            <a
              href="https://wa.me/551935736050"
              target="_blank"
              class="font-weight-bold"
              style="color: #25d366"
              >WhatsApp</a
            >{{ $t('student.student_problemadasenha.problema4') }}
          </div>
        </v-card-text>
        <v-card-actions
          class="d-flex align-center justify-center mx-md-10 mx-lg-16 pb-6"
        >
          <v-btn
            href="https://wa.me/551935736050"
            target="_blank"
            class="rounded-lg px-5"
            color="#25D366"
            dark
          >
            <v-icon left>mdi-whatsapp</v-icon> WhatsApp
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
// const token = "Baerer " + localStorage.getItem("accessToken");
// const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      successDialog: false,
      verifyCodeLoding: false,
      sendCodeLoading: false,
      userRequest: null,
      noAccessDialog: false,
      userId: null,
      phoneNumber: [],
      step: 1,
      steps: [20, 0, 0, 0],
      otp: "",
      noNumber: false,
      incorrectUser: false,
      incorrectCode: false,
      OTPCode: null,
      showPassword1: false,
      showPassword2: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
  },
  methods: {
    backStep() {
      if (this.step === 1) {
        this.$router.push("/login");
      } else {
        this.steps[this.step - 1] = 0;
        this.step--;
        let index = this.step - 1;
        this.steps[index] = 20;
      }
    },

    async getNumber(user) {
      let validation = this.$refs.userForm.validate();

      if (validation) {
        try {
          let res = await axios.get(
            `${url}/recoveryPassword/getNumber/${user}`
          );
          let number = res.data.success.phone;
          this.userId = res.data.success.id;
          this.incorrectUser = false;
          if (number !== null) {
            this.noNumber = false;
            this.steps[0] = 100;
            this.steps[1] = 20;
            this.step++;
            this.phoneNumber[0] = number;
            this.phoneNumber[1] = this.hidePhoneNumber(number);
          } else {
            this.noNumber = true;
          }
        } catch (error) {
          console.error(error);
          this.incorrectUser = true;
        }
      }
    },
    hidePhoneNumber(phoneNumber) {
      let areaCode = phoneNumber.substring(0, 6);
      let lastTwoDigits = phoneNumber.slice(-2);

      let asterisks = "*".repeat(phoneNumber.length - 8);
      let hiddenPhoneNumber = areaCode + asterisks + lastTwoDigits;

      return hiddenPhoneNumber;
    },
    async sendCode(phone) {
      this.sendCodeLoading = true;
      await axios.get(`${url}/recoveryPassword/sendVerify/${phone}`);

      setTimeout(() => {
        this.sendCodeLoading = false;
        this.steps[1] = 100;
        this.steps[2] = 20;
        this.step++;
      }, 600);
    },

    async verifyCode(phone, code) {
      let validation = this.$refs.verifyCodee.validate();

      if (validation) {
        this.verifyCodeLoding = true;
        try {
          let res = await axios.get(
            `${url}/recoveryPassword/checkValidation/${phone}/${code}`
          );

          setTimeout(() => {
            this.verifyCodeLoding = false;

            let status = res.data.verification.status;
            if (status === "approved") {
              this.steps[2] = 100;
              this.steps[3] = 20;
              this.step++;
              this.incorrectCode = false;
            } else {
              this.incorrectCode = true;
            }
          }, 1000);
        } catch (error) {
          console.error(error);
        }
      }
    },

    resendCode() {
      console.log(this.$t('student.student_confirmatelefone.confirma12'));
    },

    changePassword() {
      let validation = this.$refs.changingForm.validate();

      if (validation) {
        try {
          let body = {
            id: this.userId,
            password: this.confirmPassword,
          };

          axios.put(`${url}/recoveryPassword/alterPassword`, body);
          this.successDialog = true;

          setTimeout(() => {
            this.successDialog = false;
            this.$router.push("/login");
          }, 1200);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
