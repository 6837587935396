<template>
  <v-app id="inspire" style="background-color: #dcebff">
    <div
      style="width: 100vw"
      class="header l-section l-section--slanted mt-n16"
    >
      <div
        class="
          d-flex
          flex-row
          justify-space-between
          align-center align-self-center
          mx-auto
          l-section__content
        "
        :style="$vuetify.breakpoint.smAndDown ? 'width: 90%' : 'width: 80%'"
      >
        <v-img
          src="../assets/Logo/SVG/WhiteYellow.svg"
          :max-width="$vuetify.breakpoint.xsOnly ? 150 : 180"
          class="has-show ml-3 mt-n1 mt-md-n3"
          eager
          @click="toHome()"
        ></v-img>

        <v-btn
          color="darkpink"
          dark
          @click="toLogin()"
          class="px-5 px-sm-8 py-5 has-show rounded-lg"
        >
          {{ $t('home.home_login') }}
          <v-icon right>mdi-login</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="darkcolor--text font-weight-bold text-center has-show mt-4">
      <div style="font-size: 2.5rem">{{ $t('home.home_duvidas') }}</div>
    </div>
    <v-row no-gutters class="backcolor">
      <v-col cols="11" md="10" lg="10" class="mx-auto">
        <v-row
          v-for="(ques, i) in questions"
          :key="i"
          class="my-16 my-md-10 my-md-16"
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            :md="!!ques.image_br ? 8 : 10"
            :lg="!!ques.image_br ? 6 : 8"
            order="1"
            :order-md="checkIndex(i)"
            class="mx-auto"
          >
            <div
              class="font-weight-bold"
              :class="
                !!ques.image_br
                  ? `text-${colorLook(i)}`
                  : `text-center text-${colorLook(i)}`
              "
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.9rem'
                  : 'font-size: 1.1rem'
              "
            >
              {{ ques.q_br }}
            </div>
            <div
              class="text-justify mt-2 mt-md-4"
              style="text-indent: 2rem"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.1rem'
                  : 'font-size: 1rem'
              "
            >
              <span v-html="ques.a_br"></span>
            </div>
            <div
              :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-center' : ''"
            >
              <v-btn
                v-if="!!ques.btn_url_br"
                rounded
                color="darkpink"
                dark
                :href="ques.btn_url_br"
                min-width="150"
                class="mx-auto mt-4"
              >
                {{ ques.btn_text_br }}
                <v-icon right small class="pr-1" v-if="!!ques.btn_icon">
                  {{ ques.btn_icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            v-if="!!ques.image_br && ques.id !== 3"
            class="d-flex flex-column justify-center align-center pa-0"
            order="2"
            :order-md="checkIndex(i) === 1 ? 2 : 1"
          >
            <img :src="ques.image_br" class="rounded-xl faq-image" />
            <span
              v-if="!!ques.img_text_br"
              class="font-weight-medium"
              style="font-size: 0.75rem"
              v-html="ques.img_text_br"
            >
            </span>
          </v-col>
          <v-col
            v-if="!!ques.image_br && ques.id === 3"
            class="d-flex flex-row justify-center align-center pa-0"
            order="2"
            :order-md="checkIndex(i) === 1 ? 2 : 1"
          >
            <div class="faq-image">
              <img :src="ques.image_br.womanImg" style="width: 50%" />
              <img
                :src="ques.image_br.manImg"
                style="width: 50%"
                class="ml-n2 ml-sm-n4 ml-md-n6 ml-lg-n8"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer dark padless class="mt-6 mt-md-16">
      <v-card flat tile style="width: 100%">
        <v-row class="darkblue px-4" no-gutters>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/StarMoon.svg"
              max-width="120"
              class="float-left"
              style="transform: scale(0.9)"
            />
          </v-col>

          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="4" sm="3" md="2">
            <v-img
              src="../assets/Logo/SVG/WhiteYellow.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 120 : 150"
              class="mx-auto my-6 my-md-4"
            ></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="6"
            lg="5"
            class="text-end d-flex"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ' flex-column'
                : 'justify-space-between'
            "
            align-self="center"
            style="opacity: 0.9"
          >
            <div>
              <a
                href="/privacypolicy"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_termPriv') }}
              </a>
            </div>

            <v-divider
              color="white"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <a
                href="/termsofuse"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('hometeacher.hometeacher_termo.termo2') }}
              </a>
            </div>

            <v-divider
              color="#ffffff"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>

            <div>
              <v-icon small>mdi-whatsapp</v-icon>
              <a
                href="https://wa.me/551935736050"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_suporte') }}
              </a>
            </div>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/Spaceship.svg"
              max-width="120"
              class="float-right"
              style="transform: scale(0.9)"
            />
          </v-col>
        </v-row>

        <v-card-text
          class="py-2 white--text text-center"
          style="background-color: #0d3d70"
        >
          {{ new Date().getFullYear() }} -
          <strong>{{ $t('home.home_respostaDaPergunta.resposta2') }}</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
// const axios = require("axios");
// const url = process.env.VUE_APP_API_URL;
// const token = "Baerer " + localStorage.getItem("accessToken");
const FAQuestions = require("./FAQ.js");

export default {
  data() {
    return {
      questions: [],
      colors: ["yellow", "pink", "blue"],
    };
  },
  created() {
    this.questions = FAQuestions[0];
    this.generateRandomNumbers();
  },
  mounted() {
    document.title = this.$t('home.home_duvidas');
  },
  methods: {
    generateRandomNumbers() {
      let womanNumber = Math.floor(Math.random() * 6);
      let manNumber = Math.floor(Math.random() * 6);

      let manImg = `https://makereducacional.com.br/astronaut/tones/Man_Tone-${
        manNumber + 1
      }.svg`;
      let womanImg = `https://makereducacional.com.br/astronaut/tones/Woman_Tone-${
        womanNumber + 1
      }.svg`;

      const question = this.questions.find((question) => question.id === 3);
      if (question) {
        question.image_br = {
          manImg,
          womanImg,
        };
      }
    },
    checkIndex(index) {
      let order = [1, 2];

      let position = (index + 1) % 2;
      let res = order[position];
      return res;
    },
    colorLook(index) {
      let position = (index + 1) % 3;
      let color = this.colors[position];
      return color;
    },
    toHome() {
      this.$router.push("/");
    },
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.l-section {
  transform: skewY(-1deg);
}

.l-section__content {
  transform: skewY(1deg);
}

.custom-dialog {
  align-self: flex-end;
}

.header {
  background-image: linear-gradient(
    to bottom,
    #0c2f55 0,
    #0c2f55 70%,
    #0d3d70 30%
  );
  background-size: cover;
  width: 100%;
  min-height: 35vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

@media (max-width: 959px) {
  .header {
    min-height: 23vh; /* Update the min-height value for small screens */
  }
}

.barra {
  width: 65%;
  height: 2px;
  margin: 0 auto;
  border-radius: 1rem;
  border: 0px;
}

.image-border {
  border: solid 5px #197cff;
  border-radius: 5px;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.404));
  transition: all 200ms;

  &:hover {
    border: solid 5px #0e4887;
    transform: rotate3d(1, 1, 1, 15deg) scale(1.1);
  }
}

.has-show {
  animation: has-show 2s cubic-bezier(0, 0.55, 0.45, 1) forwards;
}

@keyframes has-show {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.text-pink {
  color: #fd80cd;
  -webkit-text-stroke: 1px #e245a6;
}

.text-yellow {
  color: #f9c228;
  -webkit-text-stroke: 1px #cf9c0e;
}

.text-blue {
  color: #1670e8;
  -webkit-text-stroke: 1px #0e4887;
}

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.faq-image {
  max-height: 70vh;
}
</style>
