<template>
  <v-app style="background-color: #dcebff">
    <v-row align="center" justify="center">
      <v-card
        :width="$vuetify.breakpoint.smAndUp ? '35%' : '85%'"
        :height="$vuetify.breakpoint.xsOnly ? '60vh' : '85vh'"
        class="pa-4 rounded-xl"
      >
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn icon color="black" @click="backStep()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <!-- <v-img
              src="../assets/MakerEducation/BlueMaker.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 80 : 120"
              class="has-show mx-auto"
            ></v-img> -->
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>

        <v-window v-model="step" class="px-2">
          <v-window-item :value="1">
            <v-img
              src="../assets/Forget-3.svg"
              height="180px"
              contain
              class="my-10"
            ></v-img>
            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('regisResponsavel.registro') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('regisResponsavel.inserir') }}
              </div>
            </div>
            <v-form ref="userForm">
            <v-row>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="userRequest.parent"
                color="darkblue"
                :label="$t('regisResponsavel.painel.nome')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="userRequest.email"
                color="darkblue"
                :label="$t('label.label_email.email')"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-select
                item-color="darkblue"
                color="blue"
                v-model="userRequest.schoolId"
                :items="schools"
                item-text="name"
                item-value="id"
                :label="$t('regisResponsavel.painel.escola')"
                :no-data-text="$t('regisResponsavel.resgistrocadastro')"
                :rules="[rules.required, rules.option]"
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="userRequest.teamStudent"
                color="darkblue"
                :label="$t('regisResponsavel.painel.turma')"
                :rules="[rules.required, rules.option]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="userRequest.student"
                color="darkblue"
                :label="$t('regisResponsavel.painel.aluno')"
                :rules="[rules.required, rules.option]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="5">
             <div class="d-flex flex-row-reverse">
                <v-btn
                  color="rgba(25, 124, 255, 0.25)"
                  class="blue--text my-2"
                  rounded
                  @click="SendParent(userRequest)"
                  large
                  style="font-size: 18px"
                >
                  {{ $t('botoes.botao_continuar') }}
                </v-btn>
              </div>
            </v-col>
            <v-alert
              v-if="incorrectUser"
              dense
              outlined
              text
              type="error"
              class="mt-n4"
            >
                {{ $t('regisResponsavel.usuarioexiste') }}
            </v-alert>
            </v-row>
            </v-form>
          </v-window-item>
                    <v-window-item :value="2">
            <v-img
              src="../assets/Forget-2.svg"
              height="180px"
              contain
              class="mb-10 mt-6"
            >
            </v-img>

            <div class="text-h6 text-sm-h5 font-weight-medium">
              {{ $t('regisResponsavel.finalizacao.finalizacao1') }}
              <br />
              <div class="text-body-2 text--black">
                {{ $t('regisResponsavel.finalizacao.finalizacao2') }}
              </div>
            </div>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="6" sm="6"></v-col>
            <v-col cols="6" sm="5">
            <div class="d-flex justify-space-between">
              <v-btn
                color="rgba(25, 124, 255, 0.25)"
                class="blue--text my-2"
                rounded
                @click="backStep()"
                :loading="sendCodeLoading"
                large
                style="font-size: 18px"
              >
                {{ $t('botoes.botao_voltar') }}
              </v-btn>
            </div>
            </v-col>
          </v-window-item>
        </v-window>
      </v-card>
    </v-row>
    <v-snackbar
      v-model="successDialog"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('regisResponsavel.sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="successDialog = false"
          color="success"
          class="font-weight-black"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
// const token = "Baerer " + localStorage.getItem("accessToken");
// const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      successDialog: false,
      verifyCodeLoding: false,
      sendCodeLoading: false,
      userRequest: {},
      schools: [],
      noAccessDialog: false,
      userId: null,
      phoneNumber: [],
      step: 1,
      steps: [20, 0, 0, 0],
      otp: "",
      noNumber: false,
      incorrectUser: false,
      incorrectCode: false,
      OTPCode: null,
      showPassword1: false,
      showPassword2: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
      password: "",
      confirmPassword: "",
    };
  },

  async mounted(){
    await axios
    .get(`${url}/schools/byParent`, {
        })
        .then((res) => {
          this.schools = res.data;
        });
  },

  methods: {
    backStep() {
      this.$router.push("/login");

    },
    async SendParent(user) {
      let validation = this.$refs.userForm.validate();
       if (validation) {
        const body = {
          email: user.email,
          parent: user.parent,
          schoolId: user.schoolId,
          student: user.student,
          teamStudent: user.teamStudent
        }
        console.log(body)
        await axios
          .post(`${url}/InsertRegister`, body,{
          })
          .then((res) => {
            console.log(res);
            this.successDialog = true
            this.step = 2   
          });        
        console.log(user)
       }
    },
  },
};
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
