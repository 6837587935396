function getLocaleFromURL() {
  const url = window.location.hostname;
  if (url.endsWith('.br')) {
    return 'pt';
  } else if (url.endsWith('.py')) {
    return 'es';
  } else {
    return 'pt';
  }
}

const dadospt = require('../locales/pt.json');

const dadoses = require('../locales/es.json');

let dados = ''

const laguage = getLocaleFromURL()
if(laguage == 'pt'){
  dados = dadospt;
}else if(laguage == 'es'){
  dados = dadoses;
}

const policy = [
  {
    initial_content:
    dados.privacy_policy.privacy_inicio,
    article: [
      {
        title: dados.privacy_policy.privacy_title1,
        content_start: null,
        content_end: null,
        items: [
          dados.privacy_policy.privacy_conteudoInicio.conteudo1,
          dados.privacy_policy.privacy_conteudoInicio.conteudo2,
          dados.privacy_policy.privacy_conteudoInicio.conteudo3,
          dados.privacy_policy.privacy_conteudoInicio.conteudo4,
        ],
      },
      {
        title: dados.privacy_policy.privacy_title2,
        content_start:
        dados.privacy_policy.privacy_conteudo1,
        content_end: null,
        items: [],
      },
      {
        title: dados.privacy_policy.privacy_title3,
        content_start:
        dados.privacy_policy.privacy_conteudo2,
        content_end: null,
        items: [],
      },
      {
        title: dados.privacy_policy.privacy_title4,
        content_start:
        dados.privacy_policy.privacy_conteudo3.conteudo1,
        content_end:
        dados.privacy_policy.privacy_conteudo3.conteudo2,
        items: [
          dados.privacy_policy.privacy_conteudo3.conteudo3,
          dados.privacy_policy.privacy_conteudo3.conteudo4,
          dados.privacy_policy.privacy_conteudo3.conteudo5,
          dados.privacy_policy.privacy_conteudo3.conteudo6,
          dados.privacy_policy.privacy_conteudo3.conteudo7,
          dados.privacy_policy.privacy_conteudo3.conteudo8,
        ],
      },
      {
        title: dados.privacy_policy.privacy_title5,
        content_start:
        dados.privacy_policy.privacy_conteudo4,
        content_end: null,
        items: [],
      },
      {
        title: dados.privacy_policy.privacy_title6,
        content_start:
        dados.privacy_policy.privacy_conteudo5,
        content_end: null,
        items: [],
      },
      {
        title: dados.privacy_policy.privacy_title7,
        content_start:
        dados.privacy_policy.privacy_conteudo6.conteudo1,
        content_end:
        dados.privacy_policy.privacy_conteudo6.conteudo2,
        items: [
          dados.privacy_policy.privacy_conteudo6.conteudo3,
          dados.privacy_policy.privacy_conteudo6.conteudo4,
          dados.privacy_policy.privacy_conteudo6.conteudo5,
          dados.privacy_policy.privacy_conteudo6.conteudo6,
          dados.privacy_policy.privacy_conteudo6.conteudo7,
        ],
      },
      {
        title: dados.privacy_policy.privacy_title8,
        content_start:
        dados.privacy_policy.privacy_conteudo7,
        content_end: null,
        items: [],
      },
      {
        title: dados.privacy_policy.privacy_title9,
        content_start:
        dados.privacy_policy.privacy_conteudo8,
        content_end: null,
        items: [],
      },
    ],
    last_changes: dados.privacy_policy.privacy_atualização,
  },
];

module.exports = [policy];
