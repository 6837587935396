<template>
  <v-app id="inspire" style="background-color: #dcebff">
    <v-btn
      fixed
      right
      fab
      absolute
      large
      color="darkcolor"
      style="bottom: 30px; right: 30px"
      class="elevation-24"
      href="https://makereducacional.com.br/termos/Termo%20de%20Uso.pdf"
      target="_blank"
    >
      <v-icon color="white">mdi-download</v-icon>
    </v-btn>
    <div
      style="width: 100vw"
      class="header l-section l-section--slanted mt-n16"
    >
      <div
        class="
          d-flex
          flex-row
          justify-space-between
          align-center align-self-center
          mx-auto
          l-section__content
        "
        :style="$vuetify.breakpoint.smAndDown ? 'width: 90%' : 'width: 80%'"
      >
        <v-img
          src="../assets/Logo/SVG/WhiteYellow.svg"
          :max-width="$vuetify.breakpoint.xsOnly ? 100 : 180"
          class="has-show ml-3 mt-n1 mt-md-n3"
          eager
          @click="toHome()"
        ></v-img>

        <v-btn
          color="darkpink"
          dark
          @click="toLogin()"
          class="px-5 px-sm-8 py-5 has-show rounded-lg"
        >
          {{ $t('home.home_login') }}
          <v-icon right>mdi-login</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="darkcolor--text font-weight-bold text-center has-show mt-4">
      <div style="font-size: 2.5rem">{{ $t('hometeacher.hometeacher_termo.termo2') }}</div>
    </div>
    <div
      :style="$vuetify.breakpoint.mdAndUp ? 'width: 50vw' : 'width: 90vw'"
      class="mx-auto mt-6 text-div has-show"
    >
      <p v-html="content[0].initial_content" class="mb-16"></p>
      <div v-for="(article, i) in content[0].article" :key="i" class="my-12">
        <p v-if="!!article.title" class="font-weight-bold">
          {{ i + 1 }}. {{ article.title }}
        </p>
        <p v-html="article.content_start" />
        <div v-for="(item, index) in article.items" :key="index" class="my-2">
          <span class="font-weight-bold">{{ i + 1 }}.{{ index + 1 }}.</span>
          <span class="ml-2" v-html="item" />
        </div>
        <div v-html="article.content_end" />
      </div>
    </div>
    <v-footer dark padless class="mt-6 mt-md-16">
      <v-card flat tile style="width: 100%">
        <v-row class="darkblue px-4" no-gutters>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/StarMoon.svg"
              max-width="120"
              class="float-left"
              style="transform: scale(0.9)"
            />
          </v-col>

          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="4" sm="3" md="2">
            <v-img
              src="../assets/Logo/SVG/WhiteYellow.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 120 : 150"
              class="mx-auto my-6 my-md-4"
            ></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="6"
            lg="5"
            class="text-end d-flex"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ' flex-column'
                : 'justify-space-between'
            "
            align-self="center"
            style="opacity: 0.9"
          >
            <div>
              <a
                href="./faq"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_duvidas') }}
              </a>
            </div>

            <v-divider
              color="white"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <a
                href="/privacypolicy"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_termPriv') }}
              </a>
            </div>

            <v-divider
              color="#ffffff"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>

            <div>
              <v-icon small>mdi-whatsapp</v-icon>
              <a
                href="https://wa.me/551935736050"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_suporte') }}
              </a>
            </div>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/Spaceship.svg"
              max-width="120"
              class="float-right"
              style="transform: scale(0.9)"
            />
          </v-col>
        </v-row>

        <v-card-text
          class="py-2 white--text text-center"
          style="background-color: #0d3d70"
        >
          {{ new Date().getFullYear() }} -
          <strong>{{ $t('home.home_respostaDaPergunta.resposta2') }}</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
// const axios = require("axios");
// const url = process.env.VUE_APP_API_URL;
// const token = "Baerer " + localStorage.getItem("accessToken");
const TermsUse = require("./TermsUse.js");

export default {
  data() {
    return {
      content: [],
      colors: ["yellow", "pink", "blue"],
    };
  },
  created() {
    this.content = TermsUse[0];
  },
  mounted() {
    document.title = this.$t('hometeacher.hometeacher_termo.termo2');
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toLogin() {
      this.$router.push("/login");
    },
    checkIndex(index) {
      let order = [1, 2];

      let position = (index + 1) % 2;
      let res = order[position];
      return res;
    },
    colorLook(index) {
      let position = (index + 1) % 3;
      let color = this.colors[position];
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.l-section {
  transform: skewY(1deg);
}

.l-section__content {
  transform: skewY(-1deg);
}

.text-div {
  font-size: 1.1rem;
  text-align: justify;
}

.custom-dialog {
  align-self: flex-end;
}

.header {
  background-image: linear-gradient(
    to bottom,
    #0c2f55 0,
    #0c2f55 70%,
    #0d3d70 30%
  );
  background-size: cover;
  width: 100%;
  min-height: 35vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

@media (max-width: 959px) {
  .header {
    min-height: 23vh; /* Update the min-height value for small screens */
  }
}

.barra {
  width: 65%;
  height: 2px;
  margin: 0 auto;
  border-radius: 1rem;
  border: 0px;
}

.image-border {
  border: solid 5px #197cff;
  border-radius: 5px;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.404));
  transition: all 200ms;

  &:hover {
    border: solid 5px #0e4887;
    transform: rotate3d(1, 1, 1, 15deg) scale(1.1);
  }
}

.has-show {
  animation: has-show 2s cubic-bezier(0, 0.55, 0.45, 1) forwards;
}

@keyframes has-show {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.text-pink {
  color: #fd80cd;
  -webkit-text-stroke: 1px #e245a6;
}

.text-yellow {
  color: #f9c228;
  -webkit-text-stroke: 1px #cf9c0e;
}

.text-blue {
  color: #1670e8;
  -webkit-text-stroke: 1px #0e4887;
}

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
