<template>
  <!-- LOGIN -->
  <v-app style="background-color: #dcebff">
    <v-row no-gutters>
      <v-col
        cols="6"
        class="login-back elevation-24"
        align-self="stretch"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-img
          src="../assets/Thunder.svg"
          class="image-login mx-auto"
          width="60%"
        />
      </v-col>
      <v-col cols="12" sm="10" md="4" class="mx-auto my-auto">
        <v-card
          class="
            px-10
            py-6
            text-center
            rounded-lg
            d-flex
            align-center
            justify-center
            flex-column
          "
          :height="$vuetify.breakpoint.xsOnly ? '100vh' : ''"
        >
          <v-form ref="loginForm">
            <div class="mb-4 text-center">
              <v-img
                src="../assets/Logo/SVG/LightBlack.svg"
                :width="$vuetify.breakpoint.xsOnly ? '250px' : '200px'"
                class="mx-auto mb-4 mt-n2 scale-up-center"
                :contain="$vuetify.breakpoint.xsOnly"
                @click="toHome()"
              ></v-img>
              {{ $t('login.login_inicio.inicio1') }}
              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('login.login_inicio.inicio2') }}
              </span>
            </div>
            <v-select
              v-model="usertype"
              :items="positions"
              filled
              :label="$t('login.login_painel.entrar')"
              color="blue"
              dense
              item-color="darkblue"
            ></v-select>
            <v-text-field
              v-model="login.username"
              color="blue"
              :label="$t('login.login_painel.login1')"
              :rules="[rules.required]"
              dense
              filled
              :placeholder="$t('login.login_painel.login2')"
              @keyup.enter="tryLogin()"
              @click="incorrectUP = false"
            />
            <v-text-field
              v-model="login.password"
              color="blue"
              :label="$t('login.login_painel.senha1')"
              dense
              :rules="[rules.required, rules.length(4)]"
              :type="showPassword ? 'text' : 'password'"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              filled
              :placeholder="$t('login.login_painel.senha2')"
              @keyup.enter="tryLogin()"
              @click="incorrectUP = false"
            />
            <v-alert
              v-if="incorrectUP"
              dense
              outlined
              text
              type="error"
              class="mt-n4"
            >
              {{ $t('login.login_painel.incorreta') }}
            </v-alert>
            <v-btn
              color="rgba(25, 124, 255, 0.25)"
              class="blue--text my-2"
              style="font-size: 18px"
              large
              block
              @click="tryLogin()"
            >
              {{ $t('login.login_painel.botao') }}
            </v-btn>

            <a href="../forgetPassword" class="caption black--text">
              {{ $t('login.login_painel.esqueci') }}
            </a>
            <div></div>
            <a href="../responsibleRecord" class="caption black--text">
              {{ $t('regisResponsavel.registro') }}
            </a>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      @click="toPedagogico()"
      @contextmenu.prevent="toAdmin()"
      class="blue--text text-caption font-weight-bold rounded-lg px-6"
      style="top: 20px; right: 15px"
      color="rgba(25, 124, 255, 0.20)"
    >
      {{ $t('login.login_colaborador') }}
    </v-btn>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      fixed
      right
      text
      large
      @contextmenu.prevent="toAdmin()"
      @click="toPedagogico()"
      class="blue--text rounded-lg linking text-subtitle-2"
      style="top: 8px"
    >
      {{ $t('login.login_outro') }}
    </v-btn>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      positions: [
        { text: this.$t('login.login_usuario.usuario1'), value: "teacher" },
        { text: this.$t('login.login_usuario.usuario2'), value: "parent" },
      ],
      usertype: "teacher",
      login: {},
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      },
      showPassword: false,
      incorrectUP: false,
    };
  },

  created() {
    localStorage.removeItem("accessToken");
  },
  methods: {
    toAdmin() {
      this.$router.push("/login/admin");
    },
    toPedagogico() {
      this.$router.push("/login/pedagogico");
    },
    toStudent() {
      this.$router.push("/student");
      setTimeout(function() {
      location.reload();
    }, 500);
    },
    toParent() {
      this.$router.push("/parent");
    },
    async tryLogin() {
      if (!this.$refs.loginForm.validate()) return;
        if (this.usertype === "teacher") {
          try {
            let access = await axios.post(`${url}/login/student`, this.login);

            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            localStorage.setItem("accessToken", access.data.accessToken);

            this.toStudent();
          } catch (error) {
            console.error("ERROR", error);
          }
       } if (this.usertype === "parent") {
           try {
            console.log(this.login)
            let access = await axios.post(`${url}/login/parent`, this.login);

            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            localStorage.setItem("accessToken", access.data.accessToken);

            this.toParent();
          } catch (error) {
            console.error("ERROR", error);
          }
       }
    },
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="css" scoped>
.login-back {
  background: linear-gradient(
    45deg,
    #07629e,
    #0e86d4,
    #5561bb,
    rgb(91, 28, 153)
  );
  background-size: 300% 300%;
  border-bottom-right-radius: 300px;
  animation: gradient 4s linear infinite alternate;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-login {
  top: 10%;
  animation: bounce 5s alternate infinite;
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.linking:hover {
  text-decoration: underline;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes bounce {
  0%,
  30%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20%,
  80% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
